import React from 'react';
import ReactDOM from 'react-dom';

const App = React.lazy(() => import('./App/App'));

ReactDOM.render(
  <React.Suspense fallback={null}>
    <App />
  </React.Suspense>,
  document.getElementById('root')
);
